<script setup></script>

<template>
  <div class="layout">
    <main>
      <slot />
    </main>
  </div>
</template>

<style lang="postcss" scoped>
.layout {
  min-height: 100svh;
  display: flex;

  flex-direction: column;
}

main {
  flex-grow: 1;
}
</style>
